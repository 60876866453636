import { paths as sharedPaths } from 'atoms/utils/constants';

// eslint-disable-next-line no-undef
const { STATIC_URL = 'https://static.lettucegrow.com' } = process.env;

const paths = {
  ...sharedPaths,
  // PDF Links
  GROWING_GUIDE_PDF: STATIC_URL + '/growing-guide.pdf',
  ASSEMBLY_GUIDES_PDF: STATIC_URL + '/assembly-guides.pdf',
  PANTRY_SHOPPING_LIST_PDF: STATIC_URL + '/pantry.pdf',
  CLIMATE_GUIDE_PDF: STATIC_URL + '/fall-climate-guide.pdf',
};

export default paths;
