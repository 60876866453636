import paths from 'constants/paths';
import 'src/theme/global.css';

import { getCookie, setStorage, getStorage } from 'utils/storageManager';
import { v1 as uuidv1 } from 'uuid';
import { axiosGet } from 'utils/api-utils';
import { openWithSearchParams, proxyWithSearchParams } from './src/utils/url-utils';

const generateItemParams = (items, discount) => {
  const params = new URLSearchParams();
  if (discount) params.append('pc', discount);
  if (items.length) {
    const itemsJSON = JSON.stringify(items);
    params.append('items', itemsJSON);
  }
  return params;
};
export const onInitialClientRender = () => {
  const netlifyTestCookie = getCookie('nf_ab');
  window.makerAddToCart = (items = [], discount) => {

    const params = generateItemParams(items, discount);
    params.append('redirect', window.location.pathname);
    openWithSearchParams(paths.CART, params.toString());
  };
  window.makerOpenModal = (modalName) => {
    proxyWithSearchParams(`?openModal=${modalName}`);
  };
  window.makerCloseModal = () => {
    proxyWithSearchParams('?closeModal=true');
  };
  window.makerLastAddedFarmstandId = (sku) => {
    proxyWithSearchParams(`?lastAddedFarmId=${sku}`);
  };
  window.makerLinkTo = (url) => {
    openWithSearchParams(url);
  };
  window.addFarmstandToCart = ({ items = [], discount, redirectTo }) => {
    const params = generateItemParams(items, discount);
    params.append('redirect', redirectTo || `${paths.FILL_YOUR_FARMSTAND_BUNDLES}?lastAddedFarmId=${items[0]?.sku}`);
    openWithSearchParams(paths.CART, params.toString());
  };
  window.openCartModal = () => {
    const params = new URLSearchParams(`?redirect=${window.location.pathname}?open=true`);
    openWithSearchParams(paths.CART, params.toString());
  };

  const lgUserId = getStorage('lgClientId');
  window.dataLayer = window.dataLayer || [];
  if (!lgUserId) {
    setStorage('lettucegrow-newUser', true);
    setStorage('lgClientId', uuidv1());
    axiosGet('/app/public/hello');
  } else {
    setStorage('lettucegrow-newUser', false);
  }
  window.dataLayer.push({ isGuest: 'true' }); // skinny app always appears in logged out state
  // push a new event to datalayer for this experiment and variant
  if (netlifyTestCookie !== null && netlifyTestCookie !== undefined) {
    window.dataLayer.push({ event: 'experience_impression', exp_variant_string: `NLF-master` });
    window.dataLayer.push({ exp_variant_string: `NLF-master` });
  }
};

export const onRouteUpdate = () => {
  window.dataLayer = window.dataLayer || [];
};
