import axios from 'axios';

import logError from 'utils/errorHandler';
import { getStorage, getCookie } from './storageManager';

const gcsUrlPrefix = process.env.GATSBY_GCS_URL_PREFIX;

// const HTTP_CODE_EXPECTATION_FAILED = 417;

// Set baseUrl
axios.defaults.baseURL = gcsUrlPrefix;

/**
 * * getUtmParams
 * @returns {object} query string utm parameters
 */
const getUtmParams = () => {
  // from GTM
  const params = window?.dataLayer?.find((data) => data.event === 'Pageview');

  if (params) {
    return {
      utm_source: (params.campaignSource || document.referrer || '').toString() || undefined,
      utm_medium: (params.campaignMedium || 'referrer').toString() || undefined,
      utm_campaign: (params.campaignName || '').toString() || undefined,
      utm_term: (params.campaignKeyword || '').toString() || undefined,
      utm_content: (params.campaignContent || '').toString() || undefined,
    };
  }
  return {};
};

const getUserIds = (url) => {
  // get analytics ids from cookie if possible
  const gaClientId = getCookie('_ga');
  const ids = {};

  if (gaClientId && ((!!gcsUrlPrefix && url.includes(gcsUrlPrefix)) || url.startsWith('/'))) {
    ids.gaClientId = gaClientId;
  }
  if (!url.includes('content') && !url.includes('yotpo')) {
    // add our own LG id if GA id is not present
    ids.lgClientId = getStorage('lgClientId');
  }

  return ids;
};

const getCredentialsOption = (url = '') => ({ withCredentials: !!url && !url?.includes('content') && !url?.includes('yotpo') });

const axiosGet = (url, config = {}) => {
  // Add any UTM params to header
  if (!url.includes('content') && !url.includes('yotpo')) {
    config.headers = Object.assign({}, config.headers, { ...getUtmParams() });
  }

  config.headers = Object.assign({}, config.headers, getUserIds(url));

  const makeRequest = () => {
    return axios
      .get(url, { ...config, ...getCredentialsOption(url) })
      .then((response) => response)
      .catch((error) => {
        // Capture the error
        logError(error, 'axiosGet');
        throw error;
      });
  };

  return makeRequest();
};

const axiosPost = (url, body, config = {}) => {
  // Add any UTM params to header

  if (!url.includes('content') && !url.includes('yotpo')) {
    config.headers = Object.assign({}, config.headers, { ...getUtmParams() });
  }

  config.headers = Object.assign({}, config.headers, getUserIds(url));

  const makeRequest = () => {
    return axios
      .post(url, body, { ...config, ...getCredentialsOption(url) })
      .then((response) => response)
      .catch((error) => {
        // Capture the error
        logError(error, 'axiosPost');
        throw error;
      });
  };

  return makeRequest();
};

const axiosDelete = (url, config = {}) => {
  // Add any UTM params to header
  if (!url.includes('content') && !url.includes('yotpo')) {
    config.headers = Object.assign({}, config.headers, { ...getUtmParams() });
  }

  config.headers = Object.assign({}, config.headers, getUserIds(url));

  const makeRequest = () => {
    return axios
      .delete(url, { ...config, ...getCredentialsOption(url) })
      .then((response) => response)
      .catch((error) => {
        // Capture the error
        logError(error, 'axiosDelete');
        throw error;
      });
  };

  return makeRequest();
};

export { axiosGet, axiosPost, axiosDelete, getUtmParams, getUserIds };
