export const getCookie = (storageKey) => {
  if (typeof window === 'undefined') return;
  let match = document.cookie.match(new RegExp('(^| )' + storageKey + '=([^;]+)'));
  if (match) {
    return match[2];
  }
  return null;
};

export const getStorage = (storageKey) => {
  if (typeof window === 'undefined') return;
  try {
    const serializedState = localStorage.getItem(storageKey);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const setStorage = (storageKey, values) => {
  if (typeof window === 'undefined') return;
  try {
    const serializedState = JSON.stringify(values);
    localStorage.setItem(storageKey, serializedState);
  } catch (err) {
    // we ignore write errors
  }
};

export const removeStorage = (storageKey) => {
  if (typeof window === 'undefined') return;
  try {
    localStorage.removeItem(storageKey);
  } catch (err) {
    // we ignore write errors
  }
};
