import paths from 'constants/paths';

/** getUrlWithSearchParams
 * @description - used for getting a page url at specified location while maintaining query parameters and optionally adding additional
 * @param {string} path - path that will be the new window location
 * @param {string} newParams - query parameters to append to the path
 * @returns {string} - the new url with query parameters
 */
const getUrlWithSearchParams = (path, newParams = '') => {
	if (!path) return '';
	if (typeof window === 'undefined') return path;
	const currentSearchParams = window.location.search ? new URLSearchParams(window.location.search) : null;
	const newSearchParams = new URLSearchParams(newParams);
	const [href, hash] = path.split('#');
	if (currentSearchParams) {
		currentSearchParams.forEach((value, key) => {
			newSearchParams.append(key, value);
		});
	}
	// if path includes a hash, append the hash after the query params
   return newSearchParams.size ? `${href}${href.includes('?') ? '&' : '?'}${newSearchParams.toString()}${hash ? `#${hash}` : ''}` : href;
}

/** openWithSearchParams
 * @description - used for opening new page at specified location while maintaining query parameters and optionally adding additional
 * @param {string} path - path that will be the new window location
 * @param {string} newParams - query parameters to append to the path
 * @returns {void}
 */
const openWithSearchParams = (path, newParams = '') => {
	if (!path) return;
  const url = getUrlWithSearchParams(path, newParams);
  window.open(url, '_self');
}

/** proxyWithSearchParams
 * @description - used for forcing the main app to be served at /proxy the redirect is always the location at the time this is called. Query params will be maintained
 * @param {string} newParams - query parameters to append to the path
 * @returns {void}
 */
const proxyWithSearchParams = (newParams = '') => {
	const searchParams = new URLSearchParams(`redirect=${window.location.pathname}${newParams}`);
	openWithSearchParams(paths.PROXY, searchParams.toString());
}

export { openWithSearchParams, proxyWithSearchParams, getUrlWithSearchParams };